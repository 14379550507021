/**
 * 当前 getters 在 mini 车也在使用，依赖的 state/getters 需要在 mini 车存在
 * 当前 getters 在 mini 车也在使用，依赖的 state/getters 需要在 mini 车存在
 * 当前 getters 在 mini 车也在使用，依赖的 state/getters 需要在 mini 车存在
 */

import {
  isFreeShipCoupon,
  selectFreeShippingModule,
  getTransportTypesFromFreeMall,
  getTransportNamesFromFreeMall,
  getTransportTypesFromCoupons,
} from '@/public/src/pages/cart_new/utils/freeShipping.js'

export default {
  // 是否展示新版的全场活动置底的免邮模块（可以凑单免邮活动，还可以凑单邮费券 wiki.pageId=1322964901）
  // 购物车置底的免邮活动, 只做单mall
  cartShippingAddPriority(state) {
    // 兼容 mini 车的 store
    let cartAbtInfo = state.cartAbtInfo || state.abtInfo
    return cartAbtInfo?.cartshippingaddpriority?.param?.cart_shipping_add_priority || 'off'
  },
  isShowNewFreeShip(state, getters) {
    return !state.isPlatform && ['A', 'B', 'C'].includes(getters.cartShippingAddPriority)
  },
  newFreeShippingInfo(state, getters) {
    if (!getters.isShowNewFreeShip) return null

    // 当前站点支持的运输方式
    const mallTransportFreeList = getTransportTypesFromFreeMall(state?.mallShippingInfo?.mall_transport_free_list)

    // 当前站点不支持任何运输方式
    if (!mallTransportFreeList.length) return null

    // 获取包含当前站点支持的运输方式的免邮券
    const getFreeShipCoupons = (list) => (list || []).filter(item =>
      isFreeShipCoupon(item) && // 免邮券
      (
        !item.shipping_list?.length || // 支持所有运输方式
        item.shipping_list.some(v => mallTransportFreeList.includes(v.shipping_method_code)) // 支持当前站点的运输方式
      )
    )

    // 满足门槛的券 & 门槛降序排
    const usableFreeShipCoupons = getFreeShipCoupons(state?.couponInfo?.usableCouponList)
    usableFreeShipCoupons.sort((a, b) => b?.other_coupon_rule?.[0]?.minOrderPrice?.amount - a?.other_coupon_rule?.[0]?.minOrderPrice?.amount)

    // 不满足门槛的券 & 差额升序排
    const disableFreeShipCoupons = getFreeShipCoupons(state?.couponInfo?.disabledCouponList?.filter(v => v?.reason != 8))
    disableFreeShipCoupons.sort((a, b) => a?.need_price - b?.need_price)

    // 获取免邮活动
    const freeShippingActivity = state.mallShippingInfo?.[getters.singleMallCode]

    // 已免邮的运输方式
    const isFreeShippingMethodTypes = [...new Set([
      ...getTransportTypesFromFreeMall(state?.mallShippingInfo?.mall_transport_free_list, 1),
      ...getTransportTypesFromCoupons(usableFreeShipCoupons, mallTransportFreeList),
    ]).values()]

    /**
     * plan A
     * 只要有至少一个运输方式免邮，就提示满足免邮权益
     */
    if (getters.cartShippingAddPriority === 'A') {
      // 已满足部分或全部免邮
      if (isFreeShippingMethodTypes.length) {
        // 有满足门槛的免邮活动，提示活动免邮
        if (freeShippingActivity?.isFree === true) {
          // 提示满足
          return selectFreeShippingModule(null, freeShippingActivity, state.language)
        }

        // 活动免邮的兜底
        // 特殊处理：freeShippingActivity 没有的情况需要在 mallShippingInfo.mall_transport_free_list 获取免邮数据
        const shippingMethodNames = getTransportNamesFromFreeMall(state?.mallShippingInfo?.mall_transport_free_list)
        if (shippingMethodNames?.length) {
          return {
            type: 'activity',
            showShipSupplement: false,
            freeShippinpTips: state.language.SHEIN_KEY_PC_29858,
            shippingMethod: shippingMethodNames?.join(','),
          }
        }

        // 有满足门槛的券，提示券免邮
        if (usableFreeShipCoupons.length) {
          return selectFreeShippingModule(usableFreeShipCoupons[0], null,  state.language)
        }

        // 不存在这种情况
        return null
      }
    }

    /**
     * plan B/C
     * 只要有至少一个运输方式未免邮，就提示用户凑单
     */

    // 未免邮的运输方式
    let noFreeShippingMethodTypes = mallTransportFreeList.filter(f => !isFreeShippingMethodTypes.includes(f))

    let filterCoupons = []
    let activity = null
    if (noFreeShippingMethodTypes.length) {
      filterCoupons = disableFreeShipCoupons.filter(item =>
        !item.shipping_list?.length || // 支持所有运输方式
        item.shipping_list.some(v => noFreeShippingMethodTypes.includes(v.shipping_method_code)) // 存在还未免邮的运输方式
      )
      if (freeShippingActivity?.isFree === false && noFreeShippingMethodTypes.includes(freeShippingActivity?.transportType)) {
        activity = freeShippingActivity
      }
    }

    // 有券没满足门槛 或 免邮活动没满足门槛，继续引导用户凑单
    if (filterCoupons?.length || activity) {
      return {
        ...selectFreeShippingModule(filterCoupons[0], activity, state.language),
        isExistFreeShippingMethodTypes: !!isFreeShippingMethodTypes.length
      }
    }

    // 以下情况为用户没有可凑单的活动或券
    // 提示免邮权益

    // 有满足门槛的免邮活动，提示活动免邮
    // 需要过滤运费活动(activity_type=3)
    if (freeShippingActivity?.isFree === true && freeShippingActivity.activity_type !== 3) {
      return selectFreeShippingModule(null, freeShippingActivity, state.language)
    }

    // 活动免邮的兜底
    // 特殊处理：freeShippingActivity 没有的情况需要在 mallShippingInfo.mall_transport_free_list 获取免邮数据
    const shippingMethodNames = getTransportNamesFromFreeMall(state?.mallShippingInfo?.mall_transport_free_list, 1)
    if (shippingMethodNames?.length) {
      return {
        type: 'activity',
        showShipSupplement: false,
        freeShippinpTips: state.language.SHEIN_KEY_PC_29858,
        shippingMethod: shippingMethodNames?.join(','),
      }
    }

    // 有满足门槛的券，提示券免邮
    if (usableFreeShipCoupons.length) {
      return selectFreeShippingModule(usableFreeShipCoupons[0], null,  state.language)
    }

    // 存在运费活动
    if (freeShippingActivity?.activity_type === 3 && freeShippingActivity.shippingTip) {
      return {
        type: 'activity',
        showShipSupplement: freeShippingActivity.showShipSupplement,
        freeShippinpTips: freeShippingActivity.shippingTip,
        shippingMethod: freeShippingActivity.shippingMethodName,
      }
    }

    // 不存在这种情况
    return null
  },
}
