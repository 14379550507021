/**
 * 与接口相关的工具函数
 */
import { getLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

/**
 * 移除购物车商品到心愿单
 * @param {String} id 购物车列表项id
 */
export const deleteCartItem = ({ id } = {}) => {
  return schttp({
    url: '/api/cart/delete',
    method: 'POST',
    data: {
      id,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 批量加车
 */
export const batchAddMall = (product_list) => {
  const data = {
    product_list,
  }
  return schttp({
    url: '/api/cart/batch_add_mall/create',
    method: 'POST',
    data,
  })
}

/**
 * 批量删除
 * @param {Array, String, Number} ids 需要删除的商品的cartId
 */
export const batchDelete = ({ ids = [], page = 'cart', filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchDelete/update',
    params: {
      ids: batchIds,
      page: page
    },
    data: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

/**
 * 批量收藏
 * @param {Array, String, Number} ids 需要收藏的商品的cartId
 */
export const batchWishGoods = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchWishGoods/update',
    method: 'POST',
    data: {
      id: batchIds,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

export const updateCart = () => {
  return schttp({
    url: '/api/cart/checkcart/get',
    params: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 获取优惠券
 */
export const getCouponListForCart = () => {
  return schttp({
    url: '/api/coupon/getNewCouponListForCart/get'
  })
}

export const getTaxTip = (params) => {
  return schttp({
    url: '/api/cart/getIncludeTaxInfo/get',
    method: 'POST',
    data: {
      ...params
      // outside params
      // 'lang': 'en', 
      // "siteUid": "pwus"
    }
  })
}

export function fetchAtomicInfo(products, fields) {
  return itemSer.getAtomicInfo({
    goods: products,
    needAssign: true,
    fields: fields,
    pageKey: 'page_cart'
  })
}

// 批量查询用户信息
export function getMembersInfo(memberIds) {
  return schttp({
    url: '/api/user/auth/batchMemberInfo/get',
    method: 'POST',
    data: {
      memberIds,
    }
  })
}

export async function queryLatestUnPaidOrderInfo() {
  const formatUnPaidOrderInfo = (orderInfo, isSupportCountryInfo) => {
    let orderData = {}
    if (isSupportCountryInfo?.info?.res == 1) {
      orderData = orderInfo?.info?.order_list?.find(order => order?.order_type == 1) || {}
    }
    return {
      traceId: [orderInfo?.traceId, isSupportCountryInfo?.traceId],
      orderData
    }
  }
  let data = await schttp({
    url: '/api/cart/queryLatestUnPaidOrderInfo/get',
    method: 'POST',
    data: {
      isClient: true // 自定义的参数, 标明客户端请求, 需要串行聚合两个接口
    }
  })
  return formatUnPaidOrderInfo(data.unPaidOrderInfo, data.isSuppoortCountry)
}

const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      console.log('获取地址缓存失败', error)
    }
  }
  return addressInfo
}

/**
 * 获取赠品列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @returns 
 */
export const getGiftProductList = ({ promotion_id, range, page, limit }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)

  return schttp({
    method: 'POST',
    url: '/promotion/gift_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取加价购换购列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {string} param.brand 品牌code
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @returns 
 */
export const getAddBuyProductList = ({ promotion_id, range, brand, page, limit }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  brand && formData.append('brand', brand)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)

  return schttp({
    method: 'POST',
    url: '/promotion/add_buy_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const getCancelOrderInfo = ({ cachedOrderIdList }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/cart/order/unpaid_order_info',
    data: {
      cachedOrderIdList,
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}
